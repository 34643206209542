// Hello world

@import "./mixins/ui";

@import "typo";
@import "./bootstrap/bootstrap";

@import "modules/header";
@import "modules/footer";

html {
  @include media-breakpoint-down('sm') {
    font-size: 13px;
  }
}

body {
  background-image: url("./img/bg_4k.png");
  background-size: 100% auto;
  font-style: italic;
  overflow-x: hidden;

  @include media-breakpoint-down('md') {
    background-size: 150% ;
  }
  @include media-breakpoint-down('sm') {
    background-size: 200% ;
  }
}

input, button, a, textarem{
  &:active {
    outline: 0 !important;
    -moz-outline-style: none;
    box-shadow: 0 0 0 !important;
  }
  &:focus {
    outline: 0 !important;
    -moz-outline-style: none;
    box-shadow: 0 0 0 !important;
  }
}


h1 {
  @include media-breakpoint-up('lg') {
    font-size: 4.5rem;
  }
}


.csl {
  &-section {
    padding-top: toRem(100px);
    padding-bottom: toRem(100px);
  }

  &-title {
    padding-top: 2rem;

    &:before {
      content: '';
      height: 4px;
      width: 50vw;
      display: block;
      background: $yellow;
      left: 0;
      bottom: -5rem;
      position: relative;

    }

    h1 {
      margin-left: 50vw;
      padding-left: 1rem;
      padding-right: 1rem;
      position: relative;
      top: -3rem;
    }

    .ico {
      margin-left: -5.5rem;
      position: relative;
      top: 2.75rem;
    }

    &_reverse {
      &:before {
        left: auto;
        right: -50vw
      }

      h1 {
        margin-left: 0;
        margin-right: 50vw;
        text-align: right;
      }

      .ico {
        right: -6rem;
      }
    }

    @include media-breakpoint-down('md') {
      h1 {
        margin-left: 0;
        margin-right: 0;
      }

      .ico {
        margin-left: 0;
        position: relative;
        top: 0;
        right:0;
      }


      &:before {

      }
    }

  }

  &-cytat {
    padding: 6rem 0;
    position: relative;

    @include media-breakpoint-down('md') {
      padding: 6rem 50px 6rem 70px;
    }


    &_right {
      width: 30px;
      top: 0px;
      bottom: 0px;
      background: url("./img/cytat_line.svg");
      display: block;
      position: absolute;
      right: -30px;
      background-repeat: repeat-y;

      &:before {
        content: '';
        background: url("./img/cycat_corner.svg");
        width: 30px;
        height: 30px;
        display: block;
        transform: rotate(180deg);
        left: -27px;
        top:-15px;
        position: relative;
      }

      &:after {
        content: '';
        background: url("./img/cycat_corner_2.svg");
        width: 30px;
        height: 30px;
        bottom: -15px;
        display: block;
        transform: rotate(180deg);
        left: -27px;
        position: absolute;
      }
    }

    &_left {
      width: 30px;
      top: 30px;
      bottom: 0px;
      background: url("./img/cytat_line.svg");
      display: block;
      position: absolute;
      left: 0px;
      font-size: 8rem;
      color: $yellow;
      font-weight: $font-weight-bolder;
      line-height: 0;
      background-repeat: repeat-y;

      &:before {

        content: '';
        width: 45px;
        height: 32px;
        display: block;
        left: -25px;
        position: relative;
        mask: url(./img/quote_grey.svg);
        top: -45px;
        background: $yellow;
      }

      &:after {
        content: '';
        background: url("./img/cycat_corner.svg");
        width: 30px;
        height: 30px;
        bottom: -15px;
        display: block;
        position: absolute;
      }
    }
  }

}

.comment{
  &-text{
    padding-left: 60px;

    &:before{
      content: '';
      width: 45px;
      height: 32px;
      display: block;
      left: -45px;
      position: relative;
      background: url(./img/quote_grey.svg);
      top: 15px;
      background-repeat: no-repeat;
    }
  }
}

.btn {
  font-weight: $font-weight-bolder;
  color: $white;
  &:focus,
  &:active,
  &:hover{
    color: $white !important;
  }
}


.kontakt-collapse-btn{
  &:before{
    content: attr(data-collapse-yes);
    display: inline-block;
  }
  &[aria-expanded="true"]{
    &:before{
      content: attr(data-collapse-no);
    }
  }

}

.btn-arrow-down {
  position: relative;
  padding-right: toRem(66px);

  &:after {
    position: absolute;
    content: '';
    right: toRem(12px);
    width: toRem(40px);
    top: toRem(8px);
    height: toRem(40px);
    mask: url("./img/strzalka_dol.svg");
    mask-repeat: no-repeat;
    background: $white;
  }
  &[aria-expanded="true"]{
    &:after {
      transform: rotate(180deg);
    }
  }
}

.page-link:hover{
  color:$white;
  background: $yellow;
}

.wp-block-image{
  img{
    max-width: 100%;
    height: auto
  }
}