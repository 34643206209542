// Bootstrap variables overwrite

$font-family-sans-serif : 'Fira Sans', sans-serif;

$font-weight-base: 300;
$font-weight-bolder: 700;

$yellow: #f7d14f;
$body-color: #353b40;
$primary: $yellow;


$link-color: $body-color;
$link-decoration: underline;
$link-hover-color: $primary;
$link-hover-decorationL: underline;

$font-size-base: toRem(18px);

$grid-gutter-width: toRem(36px);

$border-radius: 1rem;
$btn-padding-y:toRem(16px);
$btn-padding-x:toRem(26px);
$btn-font-size:toRem(24px);
$btn-line-height:toRem(32px);