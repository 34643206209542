header {
  .navbar-dark {
    background: linear-gradient(90deg, rgba(90, 93, 96, 1) 0%, rgba(128, 128, 128, 1) 100%);
  }

  .navbar-placeholder {
    padding-top: 94px;
  }

  .scrolled-down{
    transform:translateY(-100%); transition: all 0.3s ease-in-out;
  }
  .scrolled-up{
    transform:translateY(0); transition: all 0.3s ease-in-out;
  }

  #menu-menu-glowne-en,
  #menu-menu-glowne-de,
  #menu-menu-glowne{

    > li > a{

      color: $white;

      &:hover{
        color: $yellow;
      }


      &:after{
        padding-left: $nav-link-padding-y;
        content:'';
        border-right: solid 1px $white;
        display: inline-block;
        margin-left: -1px;
        height: 22px;
        margin-bottom: -5px;

        border-left: 0;
        border-top:0;
        vertical-align:0
      }

      &:before{
        padding-right: $nav-link-padding-y;
        content:'';
        border-left: solid 1px $white;
        display: inline-block;
        margin-left: -1px;
        height: 22px;
        margin-bottom: -5px;
      }

    }


    .dropdown-menu{
      background-color: #767778;
      border-radius: 0;
      border-width: 0;
      margin-top: 1.25rem;

      .dropdown-item{
        color: $white;

        &:hover{
          background-color: $yellow;
          color: $white;
        }
      }

    }


  }

  .navbar-nav{
    @include media-breakpoint-down('md') {
      display: none;
    }
  }

  .fixed-top{

    padding-top: $grid-gutter-width /2;

    .navbar{
      transition: all 0.3s ease-in-out;
    }

    &.noTop{
      margin-top: -2px;
      padding: 0;
      .navbar{
        padding-left: $grid-gutter-width /2 + $navbar-padding-x;
        padding-right: $grid-gutter-width /2 + $navbar-padding-x;
      }
    }
  }

  .navbar-hamburger{
    width:toRem(50px);
    height: toRem(50px);
    padding-right: 0;
    padding-left: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: none;
    justify-content: space-between!important;
    flex-direction: column;

    @include media-breakpoint-down('md') {
      display: flex;
    }

    i{
      width:toRem(50px);
      height: 2px;
      display: block;
      border-radius: 3px;
      background: $white;

    }


  }

  .navbar-lupa{
    padding: 0.5rem;
    img{
      max-width: toRem(50px);
    }

    &[aria-expanded="true"]{
      position: relative;
      &:before,
      &:after{
        content: '';
        width: 2px;
        height: 40px;
        display: block;
        background: $white;
        position: absolute;
        left:50%;
        top:20%;
      }
      &:after{
        transform: rotate(45deg);
      }
      &:before{
        transform: rotate(-45deg);
      }


      img{
        opacity: 0;
      }
    }

  }

  .navbar-brand{
    img{
      max-width: toRem(125px);
    }
  }


  .embed-responsive{
    color: $white;


    &:before{
      padding-top: 37.5%;
      @include media-breakpoint-down('lg') {
        padding-top: 75%;
      }
      @include media-breakpoint-down('sm') {
        padding-top: 150%;
      }
    }
  }
}

.video-banner{
 .overlay{
   background: #5a5d60c9 ;

   &-search{
     padding-top: toRem(150px);

     form.d-flex{
       padding: 1rem;
       .form-group{
         width:100%;
         max-width: 600px;
       }
     }

     .form-control{
       border-radius: 0;
       border-width: 0 0 1px 0;
       border-color: $white;
       background: transparent;
       color: $white;
     }
   }
 }
}


.verline{
  height:4.5rem;
  width: 3px;
  background: $yellow;
  display:inline-block;
  margin-left: -1rem;
  margin-right: 1rem;
  margin-bottom: -1rem;
}
.skontaktuj-sie{
  position: relative;
  text-decoration: none !important;
  color: $white !important;

  &:after{
    position: absolute;
    content: '';
    bottom:toRem(-60px);
    left: 0;
    width: toRem(250px);
    height: toRem(85px);
    background-image: url("./img/strzalka_skontaktuj_sie.svg");
    background-repeat: no-repeat;

  }
  @include media-breakpoint-down('sm') {
    font-size:1.4rem;
  }
}

#menuOverlay, #searchOverlay{
  position: fixed;
  z-index: 1029;
}

#menuOverlay.collapsing:not(.show) {
  height: 0 !important;
}

#menuOverlay.collapsing.show {
  animation: slideDown 0.5s ease;
}

@keyframes slideDown{
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 100%;
    opacity: 1;
  }
}

.menu-item.dropdown.show{

  .nav-link{
    color: $yellow !important;
  }


}
@include media-breakpoint-up('lg') {
  .layer-overlay {
    .dropdown-menu {
      padding-top: 3rem;
      background-color: transparent !important;
      border: 0;
      width: 600px;
      margin-left: -8px;
      flex-wrap: wrap !important;

      padding-bottom: 1rem;
      border-bottom: 1px solid $yellow !important;

      &.show {
        display: flex;
      }

      .dropdown-item {
        width: 31%;
        padding-left: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 0.25rem;

        &.active,
        &:hover,
        &:focus,
        &:active {
          background: transparent !important;
          color: $yellow !important;
        }

        &.active {

          border-left: 2px solid $yellow !important;
          margin-left: calc(-2px + 0.5rem);
        }

      }
    }


    &.show {
      &:after {
        position: absolute;
        content: '';
        bottom: -1rem;
        width: 24px;
        height: 24px;
        left: 50%;
        margin-left: -13px;
        background-image: url("./img/strzalka_dol.svg");
        background-repeat: no-repeat;

      }
    }
  }
}

@include media-breakpoint-down('md') {
  #menuOverlay{
    display: none;
  }
  #hamburger-controll{
    &:checked{
        & ~ .navbar-nav{
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;


          .navbar-collapse{
            display: block !important;
            .navbar-nav{
              display: block !important;
              background: linear-gradient(90deg, #5a5d60 0%, gray 100%);
              width: 100%;
              padding: 0 1rem;

              .nav-link{
                &:before,
                &:after{
                  display: none;
                }
              }

              .dropdown-menu{
                background: transparent;
              }
            }
          }

        }

    }

  }
  .navbar-collapse{
    .navbar-nav{
      transition: all 0.3s ease-in-out;
    }
  }

  .noTop{
    .navbar-collapse{
      .navbar-nav{
        padding: 0 2.125rem !important;
      }
    }
  }
}
