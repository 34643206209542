footer{
  position: relative;
  color: $white;
  padding-top: 12rem;
  overflow: hidden;
  padding-bottom: toRem(86px);

  @include media-breakpoint-down('lg') {
    padding-top: 8rem;
  }
  @include media-breakpoint-down('sm') {
    padding-top: 4rem;
  }

  &:before{
    content: "";
    position: absolute;
    width: 500%;
    height: 100%;
    top: 1rem;
    left: -250%;
    z-index: -1;
    transform: rotate(4deg);
    background: linear-gradient(0deg, rgba(37,41,45,1) 0%, rgba(53,59,64,1) 100%);
  }

  a, .nav-link{
    color: $white;
    text-decoration: underline;

    &:hover{
      color: $yellow;
      text-decoration: underline;
    }
  }

  .nav-link{
    padding: 0.15rem 0;
  }



  .navbar-brand{
    img{
      max-width: 100%;
    }
  }
  h3, .h3 {
    margin-bottom: toRem(86px);
    @include media-breakpoint-down('lg') {
      margin-bottom: toRem(46px);
    }
    @include media-breakpoint-down('sm') {
      margin-bottom: toRem(26px);
    }
  }

  .wpcf7-form{
    max-width: 400px;

    .form-control{
      border-radius: 0;
      border-width: 0 0 1px 0;
      border-color: $white;
      background: transparent;
      color: $white;
    }

    .btn{
      margin-top: 2rem;
      border-radius: 0.5rem;
      border-top-left-radius: 0;
      padding: 0.5rem 1.5rem;
    }
  }
}

.foo-hr{
  position: relative;
  &:after,
  &:before{
    height: 1px;
    width: 10000000px;
    position: absolute;
    background: $white;
    display: block;
    content: '';
    top:50%;
  }
  &:before{
    right: calc(100% + 1rem );
  }

  &:after{
    left: calc(100% + 1rem );
  }
}